import { graphql, useStaticQuery, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { Button } from "../Button/Button";
import {
  related_services_container,
  related_services,
  related_service,
  button_container,
  seeAllButton,
} from "./RelatedServices.module.scss";

const RelatedServices = ({
  articlesData,
  tagTypes = [],
  topics = [],
  client_types = [],
  authors = [],
  media_type = { id: null },
  seeAll = false,
}) => {
  const { articlesHeading, insights, case_studies, press_releases, events } =
    articlesData;

  const tagsDict = {
    topics: topics.map(({ id }) => id),
    client_types: client_types.map(({ id }) => id),
    authors: authors.map(({ id }) => id),
    media_type: [media_type.id],
  };
  const filteredTags = tagTypes
    .map(({ type }) => type)
    .reduce((prev, curr) => {
      return { ...prev, [curr]: tagsDict[curr] };
    }, {});

  const {
    allStrapiInsights: { allInsights },
    allStrapiCaseStudies: { allCaseStudies },
    allStrapiEvents: { allEvents },
    allStrapiPressRelease: { allPressReleases },
  } = useStaticQuery(graphql`
    {
      allStrapiInsights {
        allInsights: nodes {
          strapiId
          topics {
            id
            type
          }
          client_types {
            id
            Type
          }
          authors {
            id
            name
          }
          slug
          shortDesc
          publish_at
          image {
            image {
              alternativeText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          media_type {
            id
            tyoe
          }
        }
      }
      allStrapiCaseStudies {
        allCaseStudies: nodes {
          topics {
            id
            type
          }
          client_types {
            id
            Type
          }
          authors {
            id
            name
          }
          media_type {
            id
            tyoe
          }
          strapiId
          published_at
          publish_at
          mediaType
          headingText
          headerCopy
          slug
          mainImage {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      allStrapiEvents {
        allEvents: nodes {
          strapiId
          shortDesc
          slug
          subtitle
          published_at
          publish_at
          media_type {
            id
            tyoe
          }
          authors {
            id
            name
          }
          image {
            image {
              alternativeText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
      allStrapiPressRelease {
        allPressReleases: nodes {
          strapiId
          topics {
            id
            type
          }
          client_types {
            id
            Type
          }
          media_type {
            id
            tyoe
          }

          publish_at
          publish_at
          shortDesc
          slug
          subtitle
          image {
            image {
              alternativeText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `);

  const decoratedAllInsights = allInsights
    ?.map((insight) => ({
      ...insight,
      path: "articles",
    }))
    .sort((a, b) => new Date(b.publish_at) - new Date(a.publish_at));

  const decoratedAllCaseStudies = allCaseStudies
    ?.map((caseStudy) => ({
      ...caseStudy,
      path: "case-studies",
      image: { image: caseStudy?.mainImage },
      shortDesc: caseStudy?.headingText,
    }))
    .sort((a, b) => new Date(b.publish_at) - new Date(a.publish_at));

  const decoratedAllPressReleases = allPressReleases
    ?.map((pressRelease) => ({
      ...pressRelease,
      path: "press-release",
    }))
    .sort((a, b) => new Date(b.publish_at) - new Date(a.publish_at));

  const decoratedAllEvents = allEvents
    ?.map((event) => ({
      ...event,
      path: "events",
    }))
    .sort((a, b) => new Date(b.publish_at) - new Date(a.publish_at));

  const taggedArticles = [
    ...(decoratedAllInsights || []),
    ...(decoratedAllCaseStudies || []),
    ...(decoratedAllPressReleases || []),
    ...(decoratedAllEvents || []),
  ]
    .filter((article) => {
      return (
        Object.keys(filteredTags).filter((tagType) => {
          return (
            filteredTags[tagType].filter((tagId) => {
              return article[tagType]?.map((item) => item.id).includes(tagId);
            }).length > 0
          );
        }).length > 0
      );
    })
    .sort((a, b) => new Date(b.publish_at) - new Date(a.publish_at));

  const articlesByAuthor =
    filteredTags.authors && filteredTags.authors.length > 0
      ? taggedArticles.filter((article) => {
          return article?.authors
            ?.map((author) => author.id)
            .includes(filteredTags?.authors[0]);
        })
      : [];

  const insightIds = insights?.map((insight) => insight.id);
  const caseStudyIds = case_studies?.map((caseStudy) => caseStudy.id);
  const pressReleaseIds = press_releases?.map(
    (pressRelease) => pressRelease.id
  );
  const eventIds = events?.map((event) => event.id);

  const filteredInsights = decoratedAllInsights?.filter((insight) =>
    insightIds?.includes(insight.strapiId)
  );

  const filteredCaseStudies = decoratedAllCaseStudies?.filter((caseStudy) =>
    caseStudyIds?.includes(caseStudy.strapiId)
  );

  const filteredPressReleases = decoratedAllPressReleases?.filter(
    (pressRelease) => pressReleaseIds?.includes(pressRelease.strapiId)
  );

  const filteredEvents = decoratedAllEvents?.filter((event) =>
    eventIds?.includes(event.strapiId)
  );

  const allFilteredArticles = [
    ...(filteredInsights || []),
    ...(filteredCaseStudies || []),
    ...(filteredPressReleases || []),
    ...(filteredEvents || []),
  ].sort((a, b) => new Date(b.publish_at) - new Date(a.publish_at));

  let setObj = new Set();

  const latestFourArticles = [
    ...articlesByAuthor,
    ...allFilteredArticles,
    ...taggedArticles,
    ...decoratedAllInsights,
  ]
    .reduce((acc, item) => {
      if (!setObj.has(item.slug)) {
        setObj.add(item.slug, item);
        acc.push(item);
      }
      return acc;
    }, [])
    .slice(0, 4);

  return (
    <div className={related_services_container}>
      <h2>{articlesHeading || "Related Services"}</h2>
      <div className={related_services}>
        {latestFourArticles.map((service, index) => {
          return (
            <div
              key={`${service?.strapiId} + index`}
              onClick={() => navigate(`/${service.path}/${service.slug}`)}
              className={related_service}
            >
              <GatsbyImage
                image={
                  service?.image?.image?.localFile?.childImageSharp
                    ?.gatsbyImageData
                }
                alt={service?.image?.image?.alternativeText}
              />
              <h4>{service?.shortDesc}</h4>
            </div>
          );
        })}
      </div>
      {seeAll ? (
        <div className={button_container}>
          <Button
            label="See all"
            bkgColor="black"
            className={seeAllButton}
            type="internalLink"
            toPage="/insights/latest-insights"
          />
        </div>
      ) : null}
    </div>
  );
};

export default RelatedServices;
